import { delay, take } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, Inject, Injectable, Injector, Type } from '@angular/core';

import { Actions, ofActionDispatched } from '@ngxs/store';

import {
  SIDENAV_CONFIG,
  SIDENAV_DATA,
  SidenavConfig,
  SidenavDefaults,
  SidenavRef,
} from '@shared/modules/sidenav/models/sidenav.models';
import { SidenavContainer } from '@shared/modules/sidenav/components/sidenav-container/sidenav-container.component';
import { CloseLastSidenav } from '@shared/states/dialog.actions';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  constructor(
    private oc: OverlayContainer,
    private cf: ComponentFactoryResolver,
    private actions: Actions,
    private ar: ApplicationRef,
    private inj: Injector,
    @Inject(DOCUMENT) private doc: Document,
  ) {}

  openSidenav<T extends Type<any>, U = void>(component: T, config: SidenavConfig<T>): SidenavRef<U> {
    config = {
      ...SidenavDefaults,
      ...config,
      component,
    };

    const injector = Injector.create({
      parent: config.viewContainerRef?.injector || this.inj,
      providers: [
        { provide: SIDENAV_CONFIG, useValue: config },
        { provide: SIDENAV_DATA, useValue: config.data },
      ],
    });

    const outlet = new DomPortalOutlet(
      this.createPaneElement(),
      config.ngModuleRef?.componentFactoryResolver || this.cf,
      this.ar,
      injector,
      this.doc,
    );

    const portal = new ComponentPortal<SidenavContainer<U>>(SidenavContainer);
    const { instance } = outlet.attachComponentPortal(portal);

    this.actions.pipe(ofActionDispatched(CloseLastSidenav), take(1)).subscribe((action: CloseLastSidenav) => {
      const id = action.sidenavId;

      if (!id || id === instance.config.id) {
        instance.sidenav?.closeSidenav();
      }
    });

    instance.close$
      .pipe(
        // let other listeners respond to next, before the observable is completed in destroy
        delay(1),
      )
      .subscribe(() => {
        if (portal.isAttached) {
          portal.detach();
        }

        if (outlet.hasAttached()) {
          outlet.detach();
        }

        outlet.dispose();
      });

    return instance;
  }

  private createPaneElement(): HTMLElement {
    const pane = this.doc.createElement('div');
    this.oc.getContainerElement().appendChild(pane);

    return pane;
  }
}
